<template>
  <div class="users nj-grid">
    <div class="header">
      <!-- <span class="title">Consulta de agendamentos</span> -->
      <v-form @submit.prevent="search()">
        <div class="d-flex flex-row">
          <nj-chip-select-input
            class="mr-2"
            default-text="Médico"
            :items="doctors"
            v-model="form.doctor"
          ></nj-chip-select-input>
          <nj-chip-select-input
            class="mr-2"
            default-text="Empresa"
            :items="branches"
            v-model="form.branch"
          ></nj-chip-select-input>
          <nj-date-interval-input
            class="mr-2"
            label="Período"
            ref="dateIntervalInput"
            chip
            hide-details
            @change="chageInterval"
            :default-start-at="defaultStartAt"
            :default-ends-at="defaultEndsAt"
          />
          <nj-chip-select-input
            class="mr-2"
            default-text="Status"
            :items="statusOptions"
            v-model="form.status"
          ></nj-chip-select-input>
          <nj-chip-select-input
            class="mr-2"
            default-text="Agenda"
            :items="resources"
            v-model="form.schedule_type"
          ></nj-chip-select-input>
          <nj-date-interval-input
            class="mr-2"
            label="Data de Cadastro"
            ref="creationIntervalInput"
            chip
            hide-details
            @change="chageCreationInterval"
          />
        </div>
      </v-form>

      <!-- <v-btn small color="primary" elevation="0" to="/users/new">
        <v-icon left> mdi-plus </v-icon>
        Novo Usuário
      </v-btn> -->
    </div>
    <v-data-table
      :headers="headers"
      :items="items"
      class="nj-table"
      hide-default-footer
      disable-pagination
      :loading="loading"
      loading-text="Buscando..."
      ref="dataTable"
    >
      <template v-slot:item.aex_data="{ item }">
        {{ item.aex_data | scheduleDate }}
      </template>
      <template v-slot:item.aex_dthoracadastro="{ item }">
        {{ item.aex_dthoracadastro | createdAt }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import moment from 'moment'
import Api from '@/resources/NajaSocialApi'
const defaultStartAt = moment().subtract(1, 'months')
const defaultEndsAt = moment()

const STATUS_OPTIONS = [
  { value: '', text: 'Todos' },
  { value: '2', text: 'Agendado' },
  { value: '11', text: 'Aguardando agendamento' },
  { value: '7', text: 'Aguardando na recepção' },
  { value: '4', text: 'Atendido na recepção' },
  { value: '9', text: 'Atendido pelo médico' },
  { value: '1', text: 'Cancelado' },
  { value: '3', text: 'Confirmado' },
  { value: '8', text: 'Em atendimento' },
  { value: '5', text: 'Pré-Agendado' },
  { value: '6', text: 'Pré-Atendido' },
  { value: '4', text: 'Realizado' },
]

export default {
  name: 'Users',

  watch: {
    form: {
      handler() {
        this.search()
      },
      deep: true,
    },
  },

  filters: {
    scheduleDate: function (value) {
      return moment(value, 'M/DD/YYYY').format('DD/MM/YYYY')
    },

    createdAt: function (value) {
      return moment(value, 'M/D/YYYY h:mm:ss A').format('DD/MM/YYYY HH:mm:ss')
    },
  },

  data() {
    return {
      searchTerm: '',
      loading: false,
      items: [],
      statusOptions: STATUS_OPTIONS,
      defaultStartAt: defaultStartAt.format('DD/MM/YYYY'),
      defaultEndsAt: defaultEndsAt.format('DD/MM/YYYY'),
      doctors: [],
      branches: [],
      resources: [],
      form: {
        doctor: null,
        branch_id: null,
        start_date: null,
        end_date: null,
        status: null,
        creation_start_date: null,
        creation_end_date: null,
      },
      pagination: {
        current_page: 1,
        total_entries: 0,
      },
      headers: [
        { text: 'ISN', value: 'aex_isn' },
        { text: 'Data agendamento', value: 'aex_data' },
        { text: 'Data cadastro', value: 'aex_dthoracadastro' },
        { text: 'Empresa', value: 'enterprise' },
        { text: 'Médico', value: 'med_nome' },
        { text: 'Paciente', value: 'pac_nome' },
        { text: 'Contato', value: 'contato_1' },
        { text: 'Agenda', value: 'rag_nome' },
        { text: 'Status', value: 'aex_status' },
      ],
      footerProps: {
        itemsPerPageOptions: [10, 20, 30, -1],
      },
    }
  },

  mounted() {
    this.fetch()
    // this.search()
  },

  methods: {
    chageInterval(interval) {
      this.form.start_date = interval[0]
      this.form.end_date = interval[0]
    },

    chageCreationInterval(interval) {
      this.form.creation_start_date = interval[0]
      this.form.creation_end_date = interval[0]
    },

    async fetch() {
      try {
        const branchesResult = await Api.branches()
        this.branches = branchesResult.data.branches.map(branch => {
          return { value: branch.naja_code, text: branch.name }
        })

        const doctorsResult = await Api.doctorSearch.fromRisWithSchedule()
        this.doctors = doctorsResult.data.doctors.map(doctor => {
          return { value: doctor.cod.toString(), text: doctor.nome }
        })
      } catch (error) {
        this.$root.$children[0].toast('Erro ao buscar dados')
        throw error
      }
    },

    search() {
      this.loading = true
      const interval = this.$refs.dateIntervalInput.getInterval()
      const start_date = interval[0]
        ? moment(interval[0], 'YYYY-MM-DD').format('YYYYMMDD')
        : null
      const end_date = interval[1]
        ? moment(interval[1], 'YYYY-MM-DD').format('YYYYMMDD')
        : null

      const creationInterval = this.$refs.creationIntervalInput.getInterval()
      const creation_start_date = creationInterval[0]
        ? moment(creationInterval[0], 'YYYY-MM-DD').format('YYYYMMDD')
        : null
      const creation_end_date = creationInterval[1]
        ? moment(creationInterval[1], 'YYYY-MM-DD').format('YYYYMMDD')
        : null
      const query = {
        start_date: start_date,
        end_date: end_date,
        creation_start_date: creation_start_date,
        creation_end_date: creation_end_date,
        doctor: this.form.doctor,
        status: this.form.status,
        emp_cod: this.form.branch,
        schedule_type: this.form.schedule_type,
      }

      Api.scheduleSearch(query)
        .then(response => {
          this.items = response.data.schedules

          this.resources = response.data.resources.map(resource => {
            return { value: resource.rag_cod, text: resource.rag_nome }
          })
        })
        .catch(error => {
          if (error.response?.status === 400) {
            this.$root.$children[0].toast(
              error.response.data.error || error.response.data.warn
            )
          }
        })
        .finally(() => {
          this.loading = false
          this.$scrollTop()
        })
    },
  },
}
</script>

<style scoped>
.header .v-form {
  width: 100%;
  display: flex;
}
</style>
